import React, { Component } from 'react';
import LoyaltyLanding from '../components/loyalty/loyaltyLanding';
import EDropLanding from '../components/edrop/edropLanding';
import HowItWorks from '../components/edrop/bonLookHowItWorks';
import MFAMHowItWorks from '../components/edrop/mfamHowItWorks';
import LoyaltyBenefits from '../components/loyalty/loyaltyBenefits';
import LoyaltyFlexContent from '../components/loyalty/loyaltyFlexContent';
import MfamTiers from '../components/loyalty/mfamTiers';
import AssetFAQ from '../components/collection/assetFAQ';
import UtilityManager from '../managers/Utility';
import SimpleModal from '../components/modal/modal';
import Throbber from '../components/throbber';
import { getParameterByName } from '../managers/Helpers';

class Setup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatorData: {},
      transferToken: null,
      transferCookie: null,
      asset: null,
      showModal: true,
      benefits: [],
      language: 'en',
    };
  }

  setTransferToken = (token) => {
    Object.entries(sessionStorage).map((obj) => {
      const key = obj[0];
      if (key.includes('NFT_TRANSFER')) {
        sessionStorage.removeItem(key);
      }
      return true;
    });
    // force the one in the URL to be in memory
    this.setState({
      transferCookie: 'NFT_TRANSFER_' + token,
      transferToken: token,
    });
    // sessionStorage.setItem('NFT_TRANSFER_' + token, token);
  }

  componentDidMount() {
    document.title = process.env.CREATOR_SUBDOMAIN === 'account' ? 'ethos' : process.env.SITE_NAME + ' | Join the ' + process.env.DIGITAL_ASSET_TYPE;
    if (getParameterByName('id', window.location.href)) {
      this.setTransferToken(getParameterByName('id', window.location.href));
    } else if (process.env.EDROP_CLAIM_TOKEN) {
      this.setTransferToken(process.env.EDROP_CLAIM_TOKEN);
    } else {
      window.location.href = '/';
    }
  }

  loadTransferDetails = async (transferDetails) => {
    console.log('=== WE HAVE TRASNFER DETAILS ===');
    console.log(transferDetails);
    if (transferDetails.asset) {
      this.setState({
        asset: transferDetails.asset,
        showModal: false,
      });
    }
    // get any preview benefits
    const previewBenefits = await UtilityManager.getPreviewUtility(transferDetails.asset.assetId);
    if (previewBenefits && previewBenefits.length > 0) {
      this.setState({
        benefits: previewBenefits,
      });
    }
  }

  componentWillUnmount() {
    document.title = process.env.SITE_NAME;
  }

  render() {
    const {
      language, showModal, transferCookie, transferToken,
    } = this.state;
    return (
      <div>
        <SimpleModal isOpen={showModal}>
          <Throbber throbberText="Loading membership..." />
        </SimpleModal>
        <LoyaltyLanding lang={language} onTransferDetailsLoaded={(transferDetails) => this.loadTransferDetails(transferDetails)} transferCookie={transferCookie} transferToken={transferToken} />
        
        {/* <EDropLanding onTransferDetailsLoaded={(transferDetails) => this.loadTransferDetails(transferDetails)} transferCookie={transferCookie} transferToken={transferToken} /> */}

        <LoyaltyFlexContent lang={language} />

        <MfamTiers lang={language} />

        <MFAMHowItWorks lang={language} />

        <LoyaltyBenefits lang={language} />

        <div className="storefront-faq__wrapper">
          <div className="storefront-faq">
            <div className="storefront-faq--title">FAQ</div>
            <AssetFAQ noHeader lang={language} />
          </div>
        </div>
      </div>
    );
  }
}

export default Setup;
