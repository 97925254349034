import React, { useState, useEffect } from 'react';

const MfamTiers = ({ lang }) => {
  const [showTiers, setShowTiers] = useState(true);

  const copyStrings = {
    en: {
      heading: 'MFAM LEGACY CLUB TIER BREAKDOWN',
      tableHeading: 'Benefits',
      tableColHeader1: 'Recruit',
      tableColDesc1: '0-999 Points',
      tableColHeader2: 'Silver',
      tableColDesc2: '1000+ Points',
      tableColHeader3: 'Gold',
      tableColDesc3: '2500+ Points',
      tableColHeader4: 'Platinum',
      tableColDesc4: '5000+ Points',
      tableRowDesc1: 'Welcome Reward',
      tableRowDesc2: 'Promotional Birthday Reward',
      tableRowDesc3: 'Early Access to Products and Collections',
      tableRowDesc4: 'Exclusive Discounts from Participating Partners',
      tableRowDesc5: 'Exclusive Products',
      tableRowDesc6: 'Early Sign Up for Event Access',
    },
  };

  const toggleTiers = () => {
    setShowTiers(!showTiers);
  };

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="custom-tiers__breakdown">
      <div className="custom-tiers__inner">
        {/* <div className="custom-tiers__heading" onClick={(e) => toggleTiers()}>
          {copyStrings[lang].heading}
          <a><img className={showTiers ? '-rotate' : ''} src="https://assets.heyethos.com/ethos/v2/ui/icon-down-arrow.svg" alt={copyStrings[lang].heading} /></a>
        </div> */}
        {showTiers
        && (
        <div className="custom-tiers__table">
          <table>
            <tr>
              <th>{copyStrings[lang].tableHeading}</th>
              <th>
                <div className="table-bg-recruit">
                  {copyStrings[lang].tableColHeader1}
                  <span>{copyStrings[lang].tableColDesc1}</span>
                </div>
              </th>
              <th>
                <div className="table-bg-silver">
                  {copyStrings[lang].tableColHeader2}
                  <span>{copyStrings[lang].tableColDesc2}</span>
                </div>
              </th>
              <th>
                <div className="table-bg-gold">
                  {copyStrings[lang].tableColHeader3}
                  <span>{copyStrings[lang].tableColDesc3}</span>
                </div>
              </th>
              <th>
                <div className="table-bg-platinum">
                  {copyStrings[lang].tableColHeader4}
                  <span>{copyStrings[lang].tableColDesc4}</span>
                </div>
              </th>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc1}</td>
              <td>
                <div className="tiers-recruit">&bull;</div>
              </td>
              <td><div className="tiers-silver">&bull;</div></td>
              <td><div className="tiers-gold">&bull;</div></td>
              <td><div className="tiers-platinum">&bull;</div></td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc2}</td>
              <td><div className="tiers-recruit">&bull;</div></td>
              <td><div className="tiers-silver">&bull;</div></td>
              <td><div className="tiers-gold">&bull;</div></td>
              <td><div className="tiers-platinum">&bull;</div></td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc3}</td>
              <td></td>
              <td><div className="tiers-silver">&bull;</div></td>
              <td><div className="tiers-gold">&bull;</div></td>
              <td><div className="tiers-platinum">&bull;</div></td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc4}</td>
              <td></td>
              <td><div className="tiers-silver">&bull;</div></td>
              <td><div className="tiers-gold">&bull;</div></td>
              <td><div className="tiers-platinum">&bull;</div></td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc5}</td>
              <td></td>
              <td></td>
              <td><div className="tiers-gold">&bull;</div></td>
              <td><div className="tiers-platinum">&bull;</div></td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc6}</td>
              <td></td>
              <td></td>
              <td></td>
              <td><div className="tiers-platinum">&bull;</div></td>
            </tr>
          </table>
        </div>
        )}
      </div>
    </div>
  );
};
export default MfamTiers;
