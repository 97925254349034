import moment from 'moment-mini';
import Ajax from './Ajax';

/**
* Utility helper for Account functions
*/

class Redemptions {
  constructor() {
    this.apiUrl = process.env.SERVER_URL;
  }

  getRedemptions = (membershipId, token) => {
    const that = this;
    return Ajax.get(that.apiUrl + 'Redemption/membership/' + membershipId + '/redemptions', token)
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  getVouchers = (membershipId, token) => {
    const that = this;
    return Ajax.get(that.apiUrl + 'Redemption/membership/' + membershipId + '/vouchers', token)
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  redeemVoucher = (payload, token) => {
    const that = this;
    return Ajax.post(that.apiUrl + 'Redemption/redeem', payload, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }
}

export default new Redemptions();
