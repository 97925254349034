/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { set } from 'lodash';
import moment from 'moment-mini';
import Throbber from '../throbber';
import AccountManager from '../../managers/Account';
import RedemptionsManager from '../../managers/Redemptions';

function LoyaltyRedemptions({
  assetId, tier, points, onUpdatePoints, onCloseModal,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [pointsTotal, setPointsTotal] = useState(0);
  const [tab, setTab] = useState('active');
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [redemptionSuccess, setRedemptionSuccess] = useState(false);
  const [redeemedCode, setRedeemedCode] = useState('SOMETESTCODE');
  const [redemptionsList, setRedemptionsList] = useState([]);
  const [existingVouchers, setExistingVouchers] = useState([]);
  const [loadingText, setLoadingText] = useState('Checking redemption offers! One second...');

  //   const copyToClipboard = (e) => {
  //     e.preventDefault();
  //     navigator.clipboard.writeText(fullReferralCode);
  //     toast.success('Referral url has been copied to your clipboard.');
  //   };

  const copyCodeToClipboard = (code, e) => {
    if (e) {
      e.preventDefault();
    }
    navigator.clipboard.writeText(code);
    toast.success('Code has been copied to your clipboard.');
  };

  const toggleOffers = (type) => {
    setTab(type);
  };
  
  const setOffer = (offer) => {
    setSelectedOffer(offer);
  };

  const getRedemptionsList = async () => {
    const redemptionsArray = [];
    try {
      const redemptions = await RedemptionsManager.getRedemptions(assetId, AccountManager.getToken());
      if (redemptions && redemptions.success) {
        setIsLoading(false);
        if (redemptions.redemptionConfigurations && redemptions.redemptionConfigurations.length > 0) {
          redemptions.redemptionConfigurations.forEach((redemption) => {
            if (redemption.membershipTiers.length === 0) {
              redemptionsArray.push(redemption);
            } else if (redemption.membershipTiers.includes(tier.assetVariationId)) {
              redemptionsArray.push(redemption);
            }
          });
          setRedemptionsList(redemptionsArray);
        }
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error ? error.message : 'An error occurred while getting your redemptions. Please try again later.');
    }
  };

  const getVoucherList = async (blockUi) => {
    if (blockUi) {
      setIsLoading(true);
    }
    try {
      const vouchers = await RedemptionsManager.getVouchers(assetId, AccountManager.getToken());
      if (vouchers && vouchers.success) {
        setExistingVouchers(vouchers.models);
      }
    } catch (error) {
      toast.error(error ? error.message : 'An error occurred while getting your vouchers. Please try again later.');
    }
    if (blockUi) {
      setIsLoading(false);
    }
  };

  const redeemOffer = async () => {
    setLoadingText('Redeeming offer! One second...');
    setIsLoading(true);
    try {
      const redeem = await RedemptionsManager.redeemVoucher({ redemptionId: selectedOffer }, AccountManager.getToken());
      if (redeem && redeem.success) {
        setRedeemedCode(redeem.voucher);
        setRedemptionSuccess(true);
        setTab('redeemed');
        getVoucherList(true);
        toast.success('You have successfully redeemed an offer!');
      } else {
        throw new Error();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error && error.message ? error.message : 'You don\'t have enough points to redeem this offer.');
    }
  };

  useEffect(() => {
    getRedemptionsList();
    getVoucherList();
  }, []);

  return (
    <div className="loyalty-modal">
      {isLoading && <Throbber throbberText={loadingText} />}
      <div className="loyalty-modal__header">
        <p>You have</p>
        <div className="loyalty-modal__title -emphasis">
          {pointsTotal}
          {' '}
          points
        </div>
        <div className="loyalty-modal__description">Select the discount you would like to redeem below, or view the codes you have already redeemed.</div>
      </div>
      <div className="loyalty-modal__content">

        {redemptionSuccess ? (
          <div className="loyalty-modal__success">
            <div className="loyalty-modal__subtitle">You have successfully redeemed an offer!</div>
            <div className="loyalty-modal__description -margin-bottom">Use the discount code below for 15% off you next order of at least $50!</div>
            <div className="loyalty-modal__referral--container -half">
              <input type="text" id="referralCode" value={redeemedCode} readOnly />
              <a
                href="#"
                className="button-nav loyalty-modal__referral--copy"
                onClick={(e) => copyCodeToClipboard(redeemedCode, e)}>
                Copy code
              </a>
            </div>
          </div>
        )
          : (
            <div className="loyalty-modal__toggle">
              <button
            type="button"
            className={tab === 'active' ? 'loyalty-modal__toggle-button -active' : 'loyalty-modal__toggle-button'}
            onClick={() => toggleOffers('active')}>
                Active Offers
              </button>
              <button
            type="button"
            className={tab === 'redeemed' ? 'loyalty-modal__toggle-button -active' : 'loyalty-modal__toggle-button'}
            onClick={() => toggleOffers('redeemed')}>
                Redeemed Offers
              </button>
            </div>
          )}

        {tab === 'active' ? (
          <div className="loyalty-modal__offers-list">
            {redemptionsList.map((redemption, i) => (
              <div
                key={redemption.uniqueId}
                className={selectedOffer === redemption.uniqueId ? 'loyalty-modal__offer-item -selected' : 'loyalty-modal__offer-item'}
                onClick={() => setOffer(redemption.uniqueId)}>
                <div className="loyalty-modal__offer-title">{redemption.title}</div>
                <div className="loyalty-modal__offer-description">{redemption.description}</div>
                <div className="loyalty-modal__offer-selector">
                  <img src="https://assets.heyethos.com/ethos/v2/ui/icon-big-check.png" alt="Redeem" />
                </div>
              </div>
            ))}
            
          </div>
        ) : (
          <div className="loyalty-modal__offers-list">
            {existingVouchers.length > 0 ? (
              <div>
                <p>All of your redeemed points are listed here along with the attached discount codes.</p>
                <div className="loyalty-modal__offers-container">
                  <table className="loyalty-modal__table">
                    <tr>
                      <th>Expires</th>
                      <th>Offer</th>
                      <th>Code</th>
                    </tr>

                    {existingVouchers.map((offer, i) => (
                      <tr key={i}>
                        <td>{moment(offer.expiryDate).format('MMMM DD, YYYY')}</td>
                        <td>
                          {offer.title}
                          <span>{offer.description}</span>
                        </td>
                        <td>
                          <div className="loyalty-modal__code">
                            {offer.code}
                            <a href="#" onClick={(e) => copyCodeToClipboard(offer.code, e)}>
                              <img src="https://assets.heyethos.com/ethos/ui/icon-copy-inverse.svg" alt="Copy code" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                    
                  </table>
                </div>
              </div>
            ) : (
              <p className="loyalty-modal__table-no-results">{'You haven\'t redeemed any offers yet.'}</p>
            )}
        
          </div>
        )}
        
      </div>
      {tab === 'redeemed' && (
      <div className="loyalty-modal__ctas">
        <button
            type="button"
            className="button-cancel"
            onClick={() => onCloseModal(null)}>
          Close
        </button>
      </div>
      )}
      {tab === 'active' && (
      <div className="loyalty-modal__ctas">
        {selectedOffer && (
        <button
            type="button"
            className="button-cancel"
            disabled={selectedOffer === null}
            onClick={() => setSelectedOffer(null)}>
          Cancel
        </button>
        )}
        <button
            type="button"
            className="button-redeem"
            disabled={selectedOffer === null}
            onClick={() => redeemOffer()}>
          Redeem Offer
        </button>
      </div>
      )}
    </div>
  );
}

export default LoyaltyRedemptions;
