/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

function AssetFAQ({ creatorId, noHeader }) {
  const [questions, setQuestions] = useState([]);
  const toggleAnswer = (id, e) => {
    e.preventDefault();
    const cloneQuestions = [...questions];
    cloneQuestions.forEach((faq) => {
      if (faq.id === id) {
        faq.isOpen = !faq.isOpen;
      }
    });
    setQuestions(cloneQuestions);
    console.log('toggle answer');
    console.log(e);
    console.log(id);
  };

  const faq = [
    {
      id: 1,
      title: 'How do I join the loyalty program?',
      answer: 'You are automatically enrolled into the program once you create an account on either the website.',
    },
    {
      id: 2,
      title: 'How do I accumulate points?',
      answer: '<p>Earn points with every purchase made Shop.Mfam.gg. Additional ways to earn below:</p><ul><li>[  2 pts  ] -  Earned for every $1 spent on Shop.Mfam.gg</li><li>[ 10 pts ] -  Refer a Friend - (Share link to join the program )</li></ul>',
    },
    {
      id: 3,
      title: 'Will previous shop purchases count towards my points?',
      answer: 'Yes, all current customers will be rewarded and claim all points from past purchases.',
    },
    {
      id: 4,
      title: 'How many Tiers are there?',
      answer: '<p>The loyalty program has four tiers, please see the tier breakdown below.</p><ul><li>Tier 1: Recruit (0-999 Points)</li><li>Tier 2: Silver ( 1,000 Points )</li><li>Tier 3: Gold  ( 2,500 Points )</li><li>Tier 4: Platinum ( 5000+  Points )</li></ul>',
    },
    {
      id: 5,
      title: 'How do I access my benefits?',
      answer: 'Benefits & Achievements can be  found in your personal history feed.',
    },
    {
      id: 6,
      title: 'Refer a friend',
      answer: 'You can use your link to earn points and invite friends and family to the Loyalty program.',
    },
  ];

  useEffect(() => {
    setQuestions(faq);
  }, []);

  return (
    <div className="asset-faq">
      {!noHeader
      && <h2 className="digital-asset__subheader">Frequently Asked Questions</h2>}
      {questions.map((faq) => (
        <div
          key={faq.id}
          className="asset-faq__item">
          {faq.isOpen
            ? (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                -
              </a>
            )
            : (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                +
              </a>
            )}
          <div
            className="asset-faq__item--question"
            onClick={(e) => toggleAnswer(faq.id, e)}>
            {faq.title}
          </div>
          <div
            className={faq.isOpen ? 'asset-faq__item--answer -open' : 'asset-faq__item--answer'}
            dangerouslySetInnerHTML={{ __html: faq.answer }}>
          </div>
        </div>
      ))}
      
    </div>
  );
}

export default AssetFAQ;
