/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment-mini';
import Account from '../../managers/Account';
import CreatorManager from '../../managers/Creator';
import AnnouncementsManager from '../../managers/Announcements';
import SimpleModal from '../modal/modal';
import LoyaltyReferrals from './loyaltyReferrals';
import LoyaltyRedemptions from './loyaltyRedemptions';

function LoyaltyPointsWidget({
  creatorId, points, tier, assetId,
}) {
  const [announcements, setAnnouncements] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState('');
  const [tiers, setTiers] = useState([]);
  const [percentage, setPercentage] = useState(null);
  const [showRedemptionModal, setShowRedemptionModal] = useState(false);
  const [showRereferralModal, setReferralModal] = useState(false);
  const [showPointsHistoryModal, setShowPointsHistoryModal] = useState(false);

  const tierLegend = [{
    index: 0,
    variationName: 'Recruit',
    value: 0,
    color: 'linear-gradient(100deg, rgba(255,255,255,1) 25%, rgba(163,163,163,1) 100%)',
  },
  {
    index: 1,
    variationName: 'Silver',
    value: 40,
    color: 'linear-gradient(100deg, rgba(246,246,246,1) 25%, rgba(163,163,163,1) 100%)',
  },
  {
    index: 2,
    variationName: 'Gold',
    value: 80,
    color: 'linear-gradient(100deg, rgba(245,234,152,1) 25%, rgba(154,136,21,1) 100%)',
  },
  {
    index: 3,
    variationName: 'Platinum',
    value: 100,
    color: 'linear-gradient(100deg, rgba(248,248,248,1) 20%, rgba(111,111,111,1) 100%)',
  },
  ];

  const handlePageHeight = async () => {
    const brandWidget = document.getElementById('brandWidget');
    const benefitsPanel = document.getElementById('benefitsPanel');
    // alert(brandWidget.clientHeight);
    benefitsPanel.style.minHeight = `${brandWidget.clientHeight - 50}px`;
  };

  const handleStickyWidget = () => {
    const stickyBrand = document.getElementById('brandWidget');
    const benefitsSection = document.getElementById('benefitsPanel');
    let stickyStop = 1400;
    console.log(benefitsSection);
    console.log(benefitsSection.clientHeight);
    if (benefitsSection) {
      stickyStop = benefitsSection.clientHeight + 200;
    }
    window.addEventListener('scroll', () => {
      if ((stickyBrand.clientHeight + 200) < window.innerHeight) {
        if (window.scrollY > 730 && window.scrollY < stickyStop) {
          stickyBrand.classList.add('-fixed');
          stickyBrand.classList.remove('-psuedo-fixed');
        } else if (window.scrollY > stickyStop) {
          stickyBrand.classList.remove('-fixed');
          stickyBrand.classList.add('-psuedo-fixed');
        } else {
          stickyBrand.classList.remove('-fixed');
          stickyBrand.classList.remove('-psuedo-fixed');
        }
      } else {
        stickyBrand.classList.remove('-fixed');
        stickyBrand.classList.remove('-psuedo-fixed');
      }
    });
  };

  const getTiers = async () => {
    const data = await CreatorManager.getTiersByMembershipId(assetId, Account.getToken());
    const allTiers = [];
    if (data && data.tiers && data.tiers.length > 0) {
      const totalTiers = data.tiers.length;
      data.tiers.forEach((tier, i) => {
        allTiers.push({
          index: tier.sequence,
          variationName: tier.name,
          value: tier.sequence === 0 ? 5 : Math.round((tier.sequence + 1) * (100 / totalTiers)),
          minPoints: tier.minPoints,
          maxPoints: tier.maxPoints,
        });
      });
      setTiers(allTiers);
      console.log(allTiers);
      allTiers.forEach((item) => {
        if (item.variationName === tier.variationName) setPercentage(item.value);
      });
    } else {
      toast.error('Oops. There was a problem getting tier information. Please try again later.');
    }
  };

  const displayRedemptionModal = (e) => {
    e.preventDefault();
    setShowRedemptionModal(true);
  };

  const displayPointsHistoryModal = (e) => {
    e.preventDefault();
    setShowPointsHistoryModal(true);
  };

  const displayReferralyModal = (e) => {
    e.preventDefault();
    setReferralModal(true);
  };

  const setBackgroundColorForTier = () => {
    tierLegend.forEach((item) => {
      if (item.variationName === tier.variationName) {
        setPercentage(item.value);
        setBackgroundColor(item.color);
      }
    });
  };

  useEffect(() => {
    getTiers();
    setBackgroundColorForTier();
    setTimeout(() => {
      handlePageHeight();
    }, 1000);
  }, []);

  return (
    <div
      id="brandWidget"
      className="brand-widget -loyalty-widget"
      style={{
        background: backgroundColor,
      }}>
      <SimpleModal isOpen={showPointsHistoryModal} onBeforeClose={() => setShowPointsHistoryModal(false)}>
        <div>
          Points History Modal
        </div>
      </SimpleModal>
      <SimpleModal isOpen={showRedemptionModal} onBeforeClose={() => setShowRedemptionModal(false)}>
        <LoyaltyRedemptions assetId={assetId} tier={tier} points={points} onUpdatePoints={(newPoints) => updatePoints(newPoints)} onCloseModal={() => setShowRedemptionModal(false)} />
      </SimpleModal>
      <SimpleModal isOpen={showRereferralModal} onBeforeClose={() => setReferralModal(false)}>
        <LoyaltyReferrals assetId={assetId} />
      </SimpleModal>

      <div className="brand-widget__tier--badge">
        <img src={tier.thumbnailUrl} alt={tier.variationName} />
      </div>
      <div className="brand-widget__loyalty-progress">
        <div className="brand-widget__progress-bar">
          {tiers && tiers.length > 0 && (
          <div className="brand-widget__progress-bar-overflow">
            {/* <img className="progress-bar_start" src="https://assets.heyethos.com/classicsix/ui/meter-cap-transparent.png" alt="Loyalty meter cap" /> */}
            {tiers.map((item, i) => (
              <div className={'progress-bar__label -slot' + (item.index + 1)} key={i}>{item.variationName}</div>
            ))}
            {/* <img className="progress-bar_end" src="https://assets.heyethos.com/classicsix/ui/meter-cap-transparent.png" alt="Loyalty meter cap" /> */}
            <div
                className="brand-widget__progress-bar-bar"
                style={{
                  transform: 'rotate(' + (45 + (percentage * 1.8)) + 'deg)',
                }}>
            </div>
          </div>
          )}
          {/* <div className="brand-widget__progress-label">324 pts</div> */}
          <div className="loyalty-points-widget__logo">
            {process.env.LOGO_INVERSE
              ? <img src={process.env.LOGO_INVERSE} alt={process.env.SITE_NAME} />
              : <img src={process.env.LARGE_LOGO} width={process.env.NAV_LOGO_WIDTH} alt={process.env.SITE_NAME} />}
          </div>
        </div>
      </div>
      <p>Spend $204 to gain access to Heritage Member offers!</p>
      {/* <div className="brand-widget__tier-info">
        <div className="brand-widget__tier-title">{tier ? tier.variationName : 'Unknown'}</div>
      </div> */}
      {/* <div className="brand-widget__description">{process.env.SITE_DESCRIPTION}</div> */}
      <a href={process.env.CLIENT_WEBSITE} target="_blank" className="brand-widget__button" rel="noreferrer">Shop now</a>
      {announcements.length > 0
      && (
      <div className="brand-widget__updates">
        <div className="brand-widget__updates--header">
          Updates from
          {' '}
          {process.env.SITE_NAME}
        </div>
        <div className="brand-widget__updates--container">
          {announcements.map((announcement) => (
            <div
              key={announcement.announcementId}
              className="brand-widget__updates--item">
              <div dangerouslySetInnerHTML={{ __html: announcement.textContent }}></div>
              <div className="brand-widget__updates--date">
                {moment(announcement.dateCreated).format('MMM DD, YYYY')}
              </div>
            </div>
          ))}
        </div>
        
        {/* <Link to="/account/collection" className="brand-widget__button">View more</Link> */}

      </div>
      )}
      <div className="loyalty-points-widget__features">
        <div className="loyalty-points-widget__redeem">
          Redeemable Points
          <div className="loyalty-points-widget__redeem--points">0</div>
        </div>
        <p>Redeem these points towards eligible discounts for your next purchase.</p>
        <a
          className="loyalty-points-widget__link"
          href="#"
          onClick={(e) => displayPointsHistoryModal(e)}>
          View points history
        </a>
        <a
          href="#"
          target="_blank"
          className="brand-widget__button"
          onClick={(e) => displayRedemptionModal(e)}>
          Redeem Points
        </a>

        <hr className="loyalty-points-widget__hr" />

        <div className="loyalty-points-widget__referral">
          <div className="loyalty-points-widget__referral--thumb">
            <img src="https://assets.heyethos.com/mfam/utility/thumb-birthday2.jpg" alt="Referral thumb" />
          </div>
          <div className="loyalty-points-widget__referral--title">Refer your friends!</div>
          <div className="loyalty-points-widget__referral--description">
            For every friend you invite and signs up, you will both receive 10 points towards your account!
          </div>
          <a
            href="#"
            className="brand-widget__button"
            onClick={(e) => displayReferralyModal(e)}>
            Add Friends
          </a>
        </div>
      </div>
    </div>
  );
}

export default LoyaltyPointsWidget;
