import React, { useState, useEffect } from 'react';

const LoyaltyFlexContent = ({ lang }) => {
  // const [transferCode, setTransferCode] = useState(null);

  const scrollToTop = (e) => {
    e.preventDefault();
    // const scrollToElement = document.getElementById(element);
    // scrollToElement.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="flex-content -no-margin">
      <div className="flex-content--copy -full">
        {process.env.SITE_NAME === 'Northie'
          ? (
            <div className="flex-content__inner">
              <div className="flex-content--title">This week only</div>
              <div className="flex-content--description">
                {'Joining the Northie Mountain Loyalty Program this week gives you access to our latest documentary "Northie in Norway". Join now to view it before it\'s removed!'}
              </div>
            </div>
          )
          : (
            <div>
              <h2 className="custom-landing__title">How to Earn Points</h2>
              {/* <div
                className="flex-content__inner"
                dangerouslySetInnerHTML={{ __html: process.env.CUSTOM_BENEFITS_DESCRIPTION }}>
                  
              </div> */}
              <div className="flex-content__inner">
                <p>Earn points to upgrade your Legacy Club tier and unlock more rewards:</p>
                <table className="flex-content__table">
                  <tr>
                    <td>Recruit</td>
                    <td>
                      <span>|</span>
                      Free To Join
                    </td>
                  </tr>
                  <tr>
                    <td>Silver</td>
                    <td>
                      <span>|</span>
                      1000+ Points
                    </td>
                  </tr>
                  <tr>
                    <td>Gold</td>
                    <td>
                      <span>|</span>
                      2500+ Points
                    </td>
                  </tr>
                  <tr>
                    <td>Platinum</td>
                    <td>
                      <span>|</span>
                      5000+ Points
                    </td>
                  </tr>
                </table>
                <p>{'Earn 2 points for every $1 spent on shop.mfam.gg. We will offer other ways to earn points as well! For more details about each tier\'s benefits, see the breakdown below'}</p>
              </div>
            </div>
          )}
      </div>

      {/* <div
        className="flex-content--image"
        style={{
          backgroundImage: 'url(' + process.env.ALTERNATE_BACKGROUND + ')',
        }}>
      </div> */}
    </div>
  );
};
export default LoyaltyFlexContent;
